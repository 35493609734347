import { fetchDelete, fetchGet ,fetchPost,fetchPut} from "../../router/https";

function orders(data){
        return fetchGet('/mallapp/orderinfo/getAllOrder',data)
} 
function receive(data){ 
    return fetchPut('/mallapp/orderinfo/receive/'+data)
}
function getLog(data){ 
    return fetchGet('/mallapp/orderinfo/orderlogisticsDetail/'+data)
}
function getFansOrder(data){ 
    return fetchGet('/mallapp/fans/pageFansOder',data)
}

function tAliPay(data){ 

    return fetchPost('/mallapp/trainTickets/appAlipay',data)
}
function tWXPay(data){ 

    return fetchPost('/mallapp/trainTickets/trainUnifiedOrder',data)
}

function cancelTicket(data){ 

    return fetchPut('/mallapp/ticketaircraftorderinfo',data)
}
function delTicket(data){ 

    return fetchDelete('/mallapp/ticketaircraftorderinfo/'+data)
}


function refundTicket(data){ 

        return fetchGet('mallapp/trainTickets/order/refund',data)
}


function hotelPJ(data){ 

    return fetchPost('/mallapp/goodsappraises/saveHotel',data)
}

function refundTrainTick(data){ 

    return fetchPost('/mallapp/trainTickets/order/refund',data)
}


export {


    // 订单列表
    orders,


    //确认收货
    receive,

    //物流查询
    getLog,
    //粉丝订单查询
    getFansOrder,

    // 交通 支付宝接口
    tAliPay,

    // 交通 微信接口
    tWXPay,

    // 火车票 取消订单
    cancelTicket,

    // 火车票 删除订单
    delTicket,

    //火车票退票接口
    refundTicket,

    //酒店评价
    hotelPJ, 

    //火车票 退票
    refundTrainTick
}