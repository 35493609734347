<template>
    <div class="page">
                    
                    <!-- 导航头 -->
                <Head :type="0">  <template #title>  <div>物流详情</div>  </template>  </Head>

                    <!-- 内容区 -->
                <div class="main">

                    <div class="box">
                           
                            <div class="warp">
                                 <div class="num">

                                    <img :src="$route.query.img" alt="">

                                        <div class="orderinfo">
                                               <div v-if="info" style="flex：1">{{info.logisticsDesc + ':' + info.logisticsNo }} </div>
                                                

                                        </div>
                                        <copy-text :text="info.logisticsNo" style="color:#ff9820;flex:1;">复制</copy-text>
                                </div>
                                <div class="num">

                                    <img src="../../../assets/wuliu/kache.png" alt="">

                                        <div class="orderinfo">
                                               <div>订单编号：{{$route.query.orderNo}}</div>
                                                <div>收货地址：{{$route.query.address}}</div>

                                        </div>
                                </div>
                                <ul v-if="data&&$route.query.type==0">
                                    <li v-for="item,index in data" :key="item.id" :class="{'color-gery':index!=0}"> 
                                                <p v-show="index!=0"></p>
                                                <div class="dian" v-show="index==0"> <span></span></div>
                                        <div class="title">{{item.logisticsInformation}}</div>
                                        <div class="bottom">{{item.logisticsTime}}</div>
                                          <div class="gery" v-if="index == data.length-1"></div>
                                    </li>
                                  
                                </ul>

                                <ul v-else>
                                    <li v-for="item,index in data" :key="item.id" :class="{'color-gery':index!=0}" > 
                                                <p v-show="index!=0"></p>
                                                <div class="dian" v-show="index==0"> <span></span></div>
                                        <div class="title">{{item.remark}}</div>
                                        <div class="bottom">{{item.acceptTime}}</div>
                                          <div class="gery" v-if="index == data.length-1" ></div>
                                    </li>
                                </ul>
                                

                            </div>

                    </div>
                </div>
        
    



    </div>
</template>

<script>
// 头部组件
import Head from "../../../components/head/index.vue" 

import { getLog} from '../../../api/ordersCenter/index'

// 创客物流信息
import{ makerGetLog} from '../../../api/maker/order'

import copyText from "@/components/copy-text";
    export default {
        data(){ 

            return{ 

                data:null,

                info:null

            }
        },

        created(){ 

            
            this.setRem()


            if(this.$route.query.type==0){ 

                    // 获取自营商城物流信息
                    getLog(this.$route.query.id).then(res=>{ 

                        if(res.data.code===0){ 
                            
                            this.data=res.data.data.listOrderLogisticsDetail
                            this.info = res.data.data

                           
                        }

                    })

            }else{ 

                makerGetLog({alibabaId:this.$route.query.id}).then(res=>{ 
                    if(res.data.code===0){ 
                        this.data=res.data.data.logisticsSteps[0]
                        this.info = res.data.data
                    }
                })
            }
        },

        components:{Head ,copyText}

    }
</script>

<style lang="less" scoped>
.page{ 

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    flex-direction: column;

    img{ 
        width: .853333rem;
        height: .853333rem;
    }

   .main{ 
    flex: 1;
       overflow: auto;
       background-color: #f6f6f6;
       box-sizing: border-box;

       .box{ 
           padding: .266667rem;
        //    padding-bottom: constant(safe-area-inset-bottom);
        //    padding-bottom: env(safe-area-inset-bottom);
            margin-bottom: constant(safe-area-inset-bottom);
           margin-bottom: env(safe-area-inset-bottom);


           .warp{ 

               border-radius: .266667rem;
               background-color: white;
               padding: .266667rem;
               .num{ 
                   font-size: .373333rem;
                   display: flex;
                   align-items: center;
                   border-bottom: 1px solid #e6e6e6;
                   padding:  .266667rem 0;
                   img{ 
                       margin-right: .266667rem;
                   }
               }
               ul{ 
                   margin: .4rem;
                   padding-left: .533333rem;
                   border-left: 2px solid #D8D8D8;
                    position: relative;
                    
                    
                   li{ 
                     
                       margin-bottom: .533333rem;
                       p{ 
                           width: .213333rem;
                           height: .213333rem;
                           background-color: #999;
                           border-radius: .186667rem;
                           position: absolute;
                           top: .106667rem;
                           left: -0.67rem;
                           z-index: 100;
                       }
                       .dian{ 
                           background-color:#FFC698 ;
                           width: .373333rem;
                           height: .373333rem;
                           border-radius: 50%;
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           position: absolute;
                           left: -8px;
                           span{ 
                                display: block;
                                width: .213333rem;
                                height: .213333rem;
                                background: #FF5200;
                                border-radius: 50%;
                           }
                       }
                   }
               }
           }

       }
       .color-gery{ 
           color: #ccc;
           position: relative;
           .gery{ 
                        background-color: #ffffff;
                        width: 4px;
                        height:100%;
                        position: absolute;
                        left: -0.593333rem;
                        top: 0;
                        margin-top: 0.3rem;
                        z-index:50;
                    }
       }
   }

}
</style>